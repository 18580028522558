export default defineI18nConfig(() => ({
  warnHtmlMessage: false,
  messages: {
    fr: {
      phone: '03 20 01 65 65',
      phone_link: '+33320016565',
      email: 'reservation{\'@\'}planete-croisiere.fr',
      email_link: 'mailto:reservation{\'@\'}planete-croisiere.fr',
      from: 'dès',
      button: {
        discover: 'Je découvre',
        read_more: 'En savoir plus',
        request_quote: 'Demander un devis'
      },
      form: {
        placeholder: {
          email: 'Adresse email',
          name: 'Nom et prénom',
          firstname: 'Prénom',
          lastname: 'Nom',
          civility: 'Civilité',
          coupon: 'Code coupon',
          phone: 'N° de téléphone',
          message: 'Tapez votre message ici...',
        },
        label: {
          message: 'Message',
        },
        button: {
          sign_in: 'M\'inscrire',
          send: 'Envoyer',
          quote: 'Recevoir mon devis gratuit',
          finish: 'Terminer',
        },
        select: {
          defaultLabel: 'Sélectionner',
        },
        message: {
          success: 'Votre message a bien été envoyé',
          error: 'Une erreur est survenue, veuillez réessayer',
          errorQuote: 'Merci d\'indiquer les champs requis.',
          error_input_newsletter: 'Vous devez renseigner une adresse email',
          error_add_customer_newsletter: 'Une erreur s\'est produite lors de votre inscription à la newsletter',
          success_newsletter: 'Vous êtes inscrit à la newsletter.',
          errorPhone: 'Le numéro de téléphone n\'est pas dans le bon format.',
          accept_offer: 'Votre code de -30€ a bien été pris en compte',
        },
      },
      top_banner: {
        title: 'Offre de bienvenue',
        description: '<strong>30€</strong> Offerts en vous inscrivant à la newsletter',
        link_label: 'J’en profite',
      },
      header: {
        menu: {
          destinations: 'Nos destinations',
          cruises: 'Nos croisières',
          cruiselines: 'Nos compagnies',
          about: 'À propos',
          contact: 'Contact',
          search: 'Rechercher',
          departure_port: 'Port de depart',
          ships: 'Les navires',
        },
        contact: {
          title: 'Contactez un conseiller',
          link_label: 'Envoyer un message',
          opening_days: 'Du lundi au vendredi',
          opening_hours: 'de 9h à 19h',
          weekend_opening_hours: 'Le samedi de 10h à 18h',
        },
      },
      breadcrumbs: {
        home: 'Accueil',
      },
      faq: {
        title: 'Pour bien préparer votre croisière',
        subtitle: 'Vos questions fréquentes.',
        cta: 'Voir toutes les questions',
      },
      destination: {
        title: 'Nos destinations croisière',
        discover: 'Découvrez les atouts de cette région',
        subtitle: 'Faites un tour d’horizon de nos plus belles destinations !',
        noCruise: 'Aucune croisière n\'est disponible pour cette destination',
        noDestinationSelect: 'Vous n\'avez pas encore sélectionné de destination',
        seen_all: 'Voir toutes les croisières en',
        seen: 'Voir les croisières en',
        all_destinations: 'Toutes nos destinations',
        cruises_number: '{count} croisières',
        cruises_number_list: {
          single: '{count} croisière diponibles',
          plural: '{count} croisières diponibles',
        },
      },
      cruises: {
        itinerary: 'Itinéraire',
        cabin: 'Cabines',
        select_cabin_button: 'Sélectionnez un type de cabine',
        ship: 'Le navire',
        discover_ship: 'Découvrez le ',
        week: 'semaine',
        day: 'Jour',
        all: 'Voir toutes les étapes',
        modal: {
          title: 'Votre itinéraire',
          arrival: 'Arrivée',
          departure: 'Départ',
        },
        header: {
          from: 'A partir de',
          boarding: 'Embarquement',
          duration: 'Durée',
          duration_details: '{days_count} jours / {nights_count} nuits',
          cruiseline: 'Compagnie',
          cabinType: 'Type de cabine',
          departure: 'Départ',
          button: {
            quote: 'Je demande un devis',
            departure: 'Voir les dates & tarifs',
          },
          links: {
            itinerary: 'Voir l\'itinéraire complet',
            ship: 'Voir le navire',
          },
          v2: {
            priceText: 'À partir de',
            text: 'Tarif le moins cher pour un départ le {departureDate} en cabine {cabin}.',
            complete: 'Complet (Sur demande)',
            dateAndPrice: 'Voir les dates & tarifs',
            dateAndPriceTitle: 'Dates & Tarifs',
            dateAndPriceText: 'Prix par personne à partir de*',
            detail: 'Voir le détail',
            table: {
              departure: 'Date de départ',
              complete: 'Complet',
              on_request: 'Sur demande',
              low: 'Le moins cher',
              quote: 'Obtenir un devis',
              quote_complete: 'Sur demande',
              view: 'Voir le détail',
              more: 'Voir tous les tarifs',
              minus: 'Réduire les tarifs',
              lowest_price: 'Le moins cher',
            },
          },
        },
        price_details: {
          price_included: 'Inclus dans le prix',
          price_excluded: 'Le prix ne comprend pas :',
          contact_title: 'Nos équipes sont là pour vous trouver la formule adaptée à vos besoins.',
          contact_button: 'Contactez-nous',
        },
        advisor_reinsurance: {
          overline: 'Devis gratuit et sans engagement !',
          title: 'Nos Conseillers vous attendent pour préparer votre croisière à bord du {title}',
          text: 'Partez en toute tranquillité avec Planète Croisière, votre conseiller prend en main toute l’organisation de votre croisière : administratif, itinéraires, logistique, réservations...',
          reinsurances: {
            1: {
              title: 'Vous assurer',
              description: 'Une croisière adaptée à vos besoins les plus spécifiques. Vous êtes 97% de clients satisfaits !',
            },
            2: {
              title: 'Vous proposer',
              description: 'Les meilleurs prix, grâce à des tarifs négociés avec nos partenaires.',
            },
            3: {
              title: 'Planifier avec vous',
              description: 'L\'itinéraire le plus confortable pour votre voyage.',
            },
          },
        },
      },
      cruiselines: {
        plural: 'Compagnies',
        singular: 'Compagnie',
        title: {
          top: 'Top compagnies',
          all: 'Toutes les compagnies',
        },
        sub_title: {
          main: 'Partez en croisière avec votre compagnie favorite.',
        },
        header: {
          subtitle: 'Croisière',
          boatNumber: 'Nombre de bateaux',
          creationYear: 'Année de création',
        },
        cruise: {
          departure: 'Départ',
          arrival: 'Arrivée',
          duration: 'durée',
          ship: 'Navire',
          company: 'Compagnie',
          departureDates: 'Dates de départ',
          link: 'Voir l\'itinéraire complet',
        },
      },
      contact: {
        title: 'Contactez-nous',
        question: 'Vous avez une question ?',
        invitation: 'Nous vous invitons à prendre contact avec notre agence.',
        team: 'Notre équipe de passionnés vous répond sous 24h.',
      },
      newsletter: {
        title: '<strong>30€</strong> Offerts en vous inscrivant à la newsletter',
        sentence: {
          first: 'Des offres promotionnelles exclusives',
          second: 'Des invitations à nos événements exclusifs',
          third: 'Des croisières qui vous correspondent',
        },
        advantage: {
          title: 'Partir avec Planète Croisière c’est :',
          sentence: {
            first: 'Être accompagné par des passionnés depuis plus de 20 ans',
            second: 'Vous assurez une croisière adaptée à vos besoins',
            third: 'Être conseillé par un expert dédié à votre destination',
            fourth: 'Garantir votre confort à toutes les étapes de votre voyage',
          },
        },
        footer: {
          title: '<strong>30€ Offerts</strong> en vous inscrivant à la newsletter',
        },
        cgu: 'J\'accepte de recevoir les offres exclusives de Planète Croisière',
      },
      dropdown: {
        duration: 'Sélectionner la durée du séjour',
        departure: 'Sélectionner un port de départ',
        month: 'Sélectionner un mois de départ',
        cabin: 'Sélectionner un type de cabine',
        cruiseline: 'Sélectionner une compagnie',
        budget: 'Budget par personne',
      },
      ship: {
        plural: 'Les navires',
        header: {
          subtitle: 'Navire',
        },
        advantages: {
          crew: 'Membres d’équipage',
          passenger: 'Passagers',
          deck: 'Ponts',
          cabin: 'Cabines',
          bar: 'Bars',
          restaurant: 'Restaurants',
          swimmingPool: 'Piscines',
        },
      },
      modal: {
        from: 'dès',
        departureDateContent: {
          button: 'Modifier le type de cabine',
          lowPrice: 'Le moins cher',
          notAvailable: 'Indisponible pour cette cabine',
        },
        duration: 'Sélectionner la durée du séjour',
        departure: 'Sélectionner un port de départ',
        departureDate: 'Sélectionner la date de départ',
        month: 'Sélectionner un mois de départ',
        cabin: 'Sélectionner un type de cabine',
        cabinDetail: 'Détail de la cabine',
        cruiseline: 'Sélectionner une compagnie',
        budget: 'Budget par personne',
        button: {
          cancel: 'Annuler',
          confirm: 'Confirmer',
          select: 'Sélectionner',
        },
      },
      footer: {
        middle: {
          information: {
            cruiseline: 'Compagnies',
            cruises: 'Croisières',
            stopovers: 'Escales',
          },
        },
        bottom: {
          cruiseline: {
            website: 'Planète Croisière',
            address: '15 RUE DE L\'HOSPICE 59100 ROUBAIX',
            phone: 'Téléphone : 03 20 01 65 65',
          },
          payment: 'Paiement sécurisé',
          links: [
            {
              label: 'Destinations',
              link: { name: 'destinations' },
            },
            {
              label: 'Compagnies',
              link: { name: 'cruiselines' },
            },
            {
              label: 'Le blog',
              link: 'https://blog.planete-croisiere.com/',
            },
            {
              label: 'À propos',
              link: { name: 'about' },
            },
            {
              label: 'FAQ',
              link: { name: 'faq' },
            },
            {
              label: 'Plan du site',
              link: { name: 'sitemap' },
            },
            {
              label: 'Nos engagements',
              link: { name: 'slug', params: { slug: 'nos-engagements' } },
            },
            {
              label: 'Conditions générales',
              link: { name: 'slug', params: { slug: 'conditions-generales-de-ventes' } },
            },
            {
              label: 'Mentions légales',
              link: { name: 'slug', params: { slug: 'mentions-legales' } },
            },
            {
              label: 'Utilisation des cookies',
              link: { name: 'slug', params: { slug: 'utilisation-des-cookies' } },
            },
          ],
          partners: {
            apst: 'Membre de l’Association des Professionnels du Secteur Touristique',
            atoutFrance: 'Membre de l’Agence de développement touristique de la France',
            hiscox: 'Assuré par l”assurance professionnelle HISCOX',
          },
        },
      },
      filter: {
        title: 'Filtres',
        show_more_label: 'Voir plus de filtres',
        show_less_label: 'Moins de filtres',
        remove_all_filters: 'Retirer tous les filtres',
        departure: {
          title: 'Port de départ',
          selected: 'Aucun port de départ sélectionné | <strong>1</strong> port sélectionné | <strong>{count}</strong> ports sélectionnés'
          ,
        },
        destination: {
          title: 'Destination',
          text: 'Sélectionner la destination du séjour',
        },
        cruiseline: {
          title: 'Compagnie',
          text: 'Sélectionnez une compagnie',
          count: '{count} Compagnie de sélectionné | {count} Compagnies sélectionnés',
        },
        duration: {
          title: 'Durée',
          text: 'Sélectionner la durée du séjour',
        },
        monthDeparture: {
          title: 'Dates de départ',
          text: 'Sélectionner un mois de départ',
        },
        price: {
          title: 'Budget',
          text: 'Budget par personne',
        },
        button: {
          reset: 'Reinitialiser',
          apply: 'Appliquer',
          next: 'Suivant',
        },
        ship: {
          title: 'Navire',
          text: 'Sélectionner un navire',
          count: '{count} Navire de sélectionné | {count} Navires sélectionnés',
        },
        date: {
          month: 'Mois',
          day: 'Dates exactes',
        },
      },
      about: {
        title: 'À propos',
        headerTitle: 'Prêt à lever l’ancre ?',
        link: 'Nos destinations',
        tailorMade: {
          title: 'Spécialiste de la croisière sur-mesure',
          subtitle: 'Choisir Planète Croisière, c’est...',
          content: {
            follow: {
              littleTitle: 'Être accompagné par',
              title: 'Un conseiller spécialiste de votre destination.',
              text: 'Partez en toute tranquillité avec Planète Croisière, votre conseiller prend en main toute l’organisation de votre croisière : administratif, itinéraires, logistique, réservations...',
            },
            safe: {
              littleTitle: 'C’est vous assurer',
              title: 'Une croisière adaptée à vos besoins les plus spécifiques.',
            },
            schedule: {
              littleTitle: 'C’est planifier avec vous',
              title: 'L’itinéraire le plus confortable pour votre voyage.',
            },
          },
          uniqueService: {
            title: 'Un service unique',
            content: {
              welcome: {
                title: 'Un accueil personnalisé',
                text: 'Un conseiller dédié s\'occupe de toutes vos demandes et suit votre projet du début à la fin',
              },
              search: {
                title: 'Recherche des meilleures offres',
                text: 'Notre expérience croisière nous permet de négocier les meilleurs tarifs auprès des compagnies',
              },
              folder: {
                title: 'Gestion de votre dossier de croisière',
                text: 'Votre conseiller s\'occupe de certaines formalités admnistratives et vous informe et vous conseille sur celles que vous devez accomplir',
              },
              following: {
                title: 'Suivi avant et après le départ',
                text: 'Tout au long de votre projet un contact privilégié et établi afin que vous puissiez poser les',
              },
            },
          },
        },
        team: {
          title: 'Une équipe à votre écoute',
        },
        presentation: {
          title: 'Qui sommes-nous ?',
        },
      },
      blog: {
        title: 'L’actualité du monde de la croisière',
        category: 'Actualités croisières',
        readMore: 'Lire la suite',
        viewAll: 'Voir tous les articles',
      },
      home: {
        title: 'La Croisière All-Inclusive De Vos Rêves',
        subtitle: 'Partez l\'esprit tranquille',
        cruise: {
          choose: 'Choisissez votre destination',
          discover: 'Découvrir nos croisières',
        },
        presentation: {
          link: 'Découvrir l\'équipe',
        },
      },
      message: {
        noSelectedCabin: 'Vous n\'avez pas encore sélectionné de cabine',
        text: 'Vous avez dû recevoir un email de confirmation avec un récapitulatif de votre demande. Si vous ne l\'avez pas reçu, n\'hésitez pas à consulter vos spams.',
        contactPhone: 'Un conseiller prendra contact avec vous par téléphone.',
        successTitle: 'Votre demande a bien été envoyée',
        successText: 'Vous allez recevoir un email de confirmation  avec un récapitulatif de votre demande.',
        success: {
          successTitle: 'Merci pour votre message ! Un conseiller vous rappellera sous peu pour répondre à votre demande.',
          successText: 'Nous vous répondrons sous 24 heures ouvrées. Si votre demande est urgente, nous vous invitons à nous contacter au 03 20 01 65 65.<span class="time">*Du lundi au vendredi de 9h à 19h et le samedi de 10h à 18h</span>',
        },
        newsletter: {
          title: 'Inscription à la newsletter',
          success: {
            text1: 'Merci pour votre inscription à la newsletter Planète Croisière',
            text2: 'Vous venez de recevoir un e-mail pour confirmer votre inscription à notre newsletter. Si vous ne l\'avez pas reçu, il est peut-être dans les SPAMS. Une fois votre inscription confirmée, vous commencerez à recevoir régulièrement nos offres, nos bons plans ainsi que des invitations à des événements exclusifs.',
          },
          error: 'Une erreur s\'est produite lors de votre inscription à la newsletter',
        },
      },
      search: {
        titlePage: 'Trouvez la croisière all inclusive de vos rêves !',
        title: 'Votre voyage',
        sort: {
          label: 'Trier par',
          duration: 'Durée - longue',
          durationDesc: 'Durée + longue',
          perNightBestPrice: 'Prix par nuit - cher',
          departureDateBestPrice: 'Départ le plus proche',
          bestPrice: 'Prix croissant',
          bestPriceDesc: 'Prix décroissant',
          pertinence: 'Pertinence',
          marketing: 'Marketing score',
        },
        navigation: {
          title: 'Où naviguer ?',
          text: 'Choisir la destination',
          dropdown: {
            title: 'Top Destinations',
            link: 'Voir toutes les destinations',
            count: {
              single: 'croisière',
              plural: 'croisières',
            },
            selected: {
              single: 'destination sélectionnée',
              plural: 'destinations sélectionnées',
            },
          },
          see_more: 'Voir plus',
        },
        travel: {
          title: 'Quand voyager ?',
          text: 'N\'importe quand',
          value: {
            start: 'Entre le ',
            end: ' et le ',
          },
        },
        company: {
          title: 'Quelle compagnie ?',
          text: 'Toutes les compagnies',
          dropdown: {
            link: 'Voir toutes les compagnies',
            selected: {
              single: 'compagnie sélectionnée',
              plural: 'compagnies sélectionnées',
            },
          },
        },
      },
      advisors: 'conseillers',
      cruiseline: 'Compagnie',
      readMore: 'Lire la suite',
      empty: {
        destination: 'Aucune croisière ne correspond à vos critères de recherche. Veuillez essayer avec d\'autres filtres.',
      },
      quote: {
        title: {
          first: 'Votre croisière',
          second: 'Vos informations',
        },
        cabin: {
          complete: 'complet (sur demande)',
          price: 'dès {price} € / pers',
          empty: 'Il n\'y a pas de cabine disponible avec votre sélection',
        },
        departure: {
          empty: 'Il n\'y a pas de date disponible avec votre sélection',
        },
        countAdult: '{count} adulte | {count} adultes',
        countChild: 'sans enfant | {count} enfant | {count} enfants',
        passengerNumber: 'Nombre de passagers',
        advisorText: 'Votre conseiller vous appliquera le meilleur tarif !',
        choice: {
          offer: 'Je souhaite recevoir les offres et actualités Planete Croisière et recevoir un bon de 30€ lors de ma réservation',
          call: 'Je souhaite être rappelé par un conseiller expert croisière dans les 24h ouvrées',
        },
        label: {
          adult: 'Adulte',
          children: 'Enfant',
        },
        customerReview: '+ de 35 000 clients qui nous font confiance'
      },
      guarantee: {
        title: {
          first: '<strong>1 interlocuteur dédié</strong> pour l’organisation de votre voyage',
          second: '<strong>Agence Française</strong>',
          third: '<strong>+ de 40 ans</strong> d’expérience dans le tourisme <strong>15 ans</strong> de présence sur le web',
          fourth: '<strong>Paiement Sécurisé</strong> et Facilité Paiement en plusieurs fois possible. <br> Garantie financière APST <br> Réservez en toute sécurité'
        }
      },
      btn: {
        validate: 'Valider',
        reset: 'Réinitialiser',
        next: 'Suivant',
      },
      review: {
        title: 'Ils ont adoré leur croisière',
        see: 'Voir tous les avis',
        posted: 'Publié sur Google le {date}',
      },
    },
  },
}))
